import React, { useEffect, useState } from 'react';
import CustomLoader from '../../Components/CustomLoader/CustomLoader';
import './books.css';

const status = {
    inProgress: "In Progress",
    read: "Read",
    onHold: "On Hold",
}

const Books = ({ books }) => {
    const [selectedShelves, setSelectedShelves] = useState({
        inProgress: true,
        read: true,
        onHold: false,
    });

    const toggleShelf = (shelf) => {
        setSelectedShelves(prevState => ({
            ...prevState,
            [shelf]: !prevState[shelf],
        }));
    };

    useEffect(() => {
        document.body.classList.add('bookshelf-bg');
        document.body.classList.add('wooden-bg');

        return () => {
            document.body.classList.remove('bookshelf-bg');
            document.body.classList.remove('wooden-bg');
        };
    }, []);

    const driveUrlFormat = 'https://drive.google.com/thumbnail?id=';

    if (books && books.length > 0) {
        const inProgress = [];
        const read = [];
        const onHold = [];

        books.slice(1).forEach((book) => {
            const fileId = book[4].split('/d/')[1].split('/')[0];
            const goodreadsLink = book[3];
            const bookElement = (
                <div className="book" key={book[0]}>
                    <div className="side spine">
                        <span className="spine-title">{book[0]}</span>
                    </div>
                    <div className="side top"></div>
                    <div className="side cover" style={{
                        backgroundImage: `url(${driveUrlFormat + fileId})`
                    }}>
                        <a className="goodreads-link" href={goodreadsLink} target="_blank" rel="noopener noreferrer"> </a>
                    </div>
                </div>
            );

            switch (book[2].toLowerCase()) {
                case 'in progress':
                    inProgress.push(bookElement);
                    break;
                case 'read':
                    read.push(bookElement);
                    break;
                case 'on hold':
                    onHold.push(bookElement);
                    break;
                default:
                    break;
            }
        });

        return (
            <div className="books-container">
                <div className="book-filter-tabs">
                    {['inProgress', 'read', 'onHold'].map((shelf) => (
                        <button
                            key={shelf}
                            className={`book-filter-tab ${selectedShelves[shelf] ? 'active' : ''}`}
                            onClick={() => toggleShelf(shelf)}
                        >
                            {status[shelf]}
                        </button>
                    ))}
                </div>
                <div className="bookshelf">
                    {selectedShelves.inProgress && (
                        <>
                            <h2 className="shelf-title">In Progress</h2>
                            <div className="shelf">{inProgress}</div>
                        </>
                    )}
                    {selectedShelves.read && (
                        <>
                            <h2 className="shelf-title">Read</h2>
                            <div className="shelf">{read}</div>
                        </>
                    )}
                    {selectedShelves.onHold && (
                        <>
                            <h2 className="shelf-title">On Hold</h2>
                            <div className="shelf">{onHold}</div>
                        </>
                    )}
                </div>
            </div>
        );
    } else {
        return <CustomLoader />;
    }
};

export default Books;
